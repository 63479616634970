/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
// Icons
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import ShareIcon from '@material-ui/icons/Share';

const sections = [
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Insights',
        icon: InsertChartIcon,
        href: '/'
      },
      {
        title: 'Your suggestions',
        icon: EmojiObjectsIcon,
        href: '/suggestions'
      },
      {
        title: 'Store display',
        icon: ShareIcon,
        href: '#',
        items:[
          {
            target: '_blank',
            title: 'Store 1',
            href: 'https://demo.alertiee.io/240312094248867'
          },
          {
            target: '_blank',
            title: 'Store 2',
            href: 'https://demo.alertiee.io/240312094248866'
          },
        ]
      }
    ]
  },
  // {
  //   subheader: 'Management',
  //   items: [
  //     {
  //       title: 'Customers',
  //       icon: UsersIcon,
  //       href: '/app/management/customers',
  //       items: [
  //         {
  //           title: 'List Customers',
  //           href: '/app/management/customers'
  //         },
  //         {
  //           title: 'View Customer',
  //           href: '/app/management/customers/1'
  //         },
  //         {
  //           title: 'Edit Customer',
  //           href: '/app/management/customers/1/edit'
  //         }
  //       ]
  //     },
  //   ]
  // }
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    if(item.target === '_blank'){
      acc.push(
        <NavItem
          depth={depth}
          onClick={() => {
            return window.open(item.href, "_blank");
          }}
          href="#"
          className="location"
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
        />);
    }else{
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          info={item.info}
          key={key}
          title={item.title}
        />
      );
    }

  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  StickBottom: {
    '@media screen and (min-height: 690px)':{
      position: 'absolute',
      bottom: '10px',
      width: '100%'
    }
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo style={{maxWidth: '90%'}} />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <img style={{maxWidth: '90%'}} src={`/static/icons/${user.logo}`} alt="Logo" />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
              style={{ fontWeight: '400' }}
            >
              {user.name}
            </Link>
            <Box mt={1}>
              <Typography variant="body2" color="textSecondary">
                Platform
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Box className={classes.StickBottom}>
          <Box p={2}>
            <Box
              p={2}
              borderRadius="borderRadius"
              bgcolor="background.dark"
            >
              <Grid container>
                <Grid item xs={11}>
                  <Typography
                    variant="h6"
                    color="textPrimary"
                  >
                    Need help?
                  </Typography>
                  <Link
                    variant="subtitle2"
                    color="secondary"
                    href="mailto:smalek@altifio.com"
                  >
                    email us here
                  </Link>
                </Grid>
                <Grid item xs={1}>
                  <Box display="flex" alignItems="center" height="100%">
                    <img alt="External link" src="/static/icons/external-link-second.svg"/>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box p={2}>
            <Divider />
            <Box pt={2}>
              <Typography variant="body2" color="textSecondary"> © Copyright Alertiee.io </Typography>
            </Box>
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
