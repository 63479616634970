import React, { useCallback, useState, useEffect }from 'react';
import {
    Box,
    Grid,
    Typography,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Fade,
    CircularProgress,
    makeStyles,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import axios from 'axios';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import moment from "moment";
import VisitorsFlow from './VisitorsFlow';
import WeeklyOverView from './WeeklyOverView';
import TodayVisitors from './TodayVisitors';
import ThisWeekVisitors from './ThisWeekVisitors';
import ThisMonthVisitors from './ThisMonthVisitors';
import HistoricalVisitors from './HistoricalVisitors';
import TodayVisitorsCompliance from './Compliance/TodayVisitorsCompliance';
import ThisWeekVisitorsCompliance from './Compliance/ThisWeekVisitorsCompliance';
import ThisMonthVisitorsCompliance from './Compliance/ThisMonthVisitorsCompliance';
import HistoricalVisitorsCompliance from './Compliance/HistoricalVisitorsCompliance';
import Peaks from './Peaks';
import CustomerSegment from './CustomerSegment';
import { BackendURL } from 'src/constants';

const useStyles = makeStyles((theme) => ({
    root:{
        border: `solid 1px ${theme.palette.divider}`
    },
    heading: {
        backgroundColor: theme.palette.background.default,
        borderRadius: '4px',
    },
    location: {
        backgroundColor: theme.palette.background.default,
        borderRadius: '4px',
    //   boxShadow: '1px 0px 6px 0px rgba(0,0,0,0.23)',
        border: `solid 1px ${theme.palette.divider}`,
        '&:hover':{
            cursor: 'pointer'
        }
    },
    locationInner:{
        [theme.breakpoints.up('md')]:{
            minHeight: '91px'
        },
        [theme.breakpoints.down('md')]:{
            paddingTop: '10px',
            paddingBottom: '10px',
        }
    },
    DownButton:{
        [theme.breakpoints.down('md')]:{
            maxWidth: '40px',
            maxHeight: '40px'
        },
        transition: 'all 0.5s ease',
        backgroundColor: theme.palette.background.alertiee,
        '&:hover':{
            backgroundColor: theme.palette.background.alertiee,
            opacity: 0.9
        }
    },
    leftCol:{
        borderRight: `solid 1px ${theme.palette.divider}`
    },
    dropLocationInfo:{
        backgroundColor: theme.palette.background.default,
    }
}));

const Locations = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(0);
  const [currentLocationData, setCurrentLocationData] = useState({});
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);

  const getLocations = useCallback(async () => {
    try{
    //    let data = await axios.get('URL'); 
        let data = [
            {
                // id: 240312094248867, 
                id: 240312094248866, 
                title: 'Store 1',
                // camera: 201112094304641,
                peaksSement: true,
            },
            {
                id: 240312094248866, 
                title: 'Store 2',
                camera: 0,
                peaksSement: false
            },
            {
                id: 240312094248868, 
                title: 'Store 3',
                camera: 0,
                peaksSement: false
            }
        ];
        setLocations(data);
    }catch(error){
        console.log(error);
    }
  }, []);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  if (!currentLocationData) {
    return null;
  }
  const getLocationInformation = async (id) => {
    setLoading(true);
    try{
        let header = {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'responseType': 'json',
              'Content-Type': 'application/json;charset=utf-8',
              'Api-x-key': 'H3DdKEuctPHVDWYgYOqydCAh08BFj_-hziYlrIbwvMI'
            }
        }
        let reqVal = { 
            "Client": "Adnams",
            "store_id": id,
        };
        let reqVal2 = { 
            "Client": "Adnams",
            "store_id": id,
            "date": moment().format("YYYY-MM-DD"),
        };
        let reqVal3 = { 
            "Client": "Adnams",
            "store_id": id,
            "date": moment().format("YYYY-MM-DD"),
            "weeks": 4
        };
        const res = await axios.post(BackendURL+'/operations/compliance_camera', reqVal, header); 
        const res1 = await axios.post(BackendURL+'/operations/weekly_flow', reqVal2, header); 
        const res2 = await axios.post(BackendURL+'/operations/export', reqVal3, header); 
            // console.log(res1);
        //   const res = await axios.post('//18.133.251.161/operations/flow_camera', { 'Client': 'Adnams' }, header); 
        let TodayVisitors = res.data.today;
        let thisMonth = res.data.thisMonth;
        let thisWeek = res.data.thisWeek;
        let historical = res.data.historical;

        let data = {
            id: 1,
            visitorsFlow:{
                today: {
                    value: TodayVisitors,
                    difference: res.data.growthDay === "N/A"? 0: res.data.growthDay,
                },
                thisWeek: {
                    value: thisWeek,
                    difference: res.data.growthWeek === "N/A"? 0: res.data.growthWeek,
                },
                thisMonth: {
                    value: thisMonth,
                    difference: res.data.growthMonth === "N/A"? 0: res.data.growthMonth,
                },
                histoical: historical
            },
            ComplianceLevel:{
                today: res.data.ComplianceToday,
                thisWeek: res.data.ComplianceThisWeek,
                thisMonth: res.data.ComplianceThisMonth,
                histoical: res.data.ComplianceHistorical,
            },
            chart:{
                values: res.data.chart.data,
                labels: res.data.chart.labels,
            },
            chartWeeks:{
                values: {
                    thisWeek: res1.data.chart.data_daily,
                    lastWeek: res1.data.chart.data_average,
                },
                labels: res1.data.chart.labels,
                dates: res1.data.chart.dates,
            },
            monthlyData: {
                values: res2.data.chart.data_daily ,
                labels: res2.data.chart.labels,
                dates: res2.data.chart.dates,
            }
        };
        setCurrentLocationData(data);
        setLoading(false);
    }catch(error){
        console.log(error);
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickLocation = (title, id) => {
      if(currentLocation === title){
          setCurrentLocation(0);
          return false;
      }
      setCurrentLocation(title);
      getLocationInformation(id);
  }

    return(
        <Box className={classes.root}>
            <Box p={2} className={classes.heading}>
                <Grid container>
                    <Grid item xs={10}>
                        <Typography color="textPrimary" variant="h3">Locations</Typography>
                        <Typography color="textPrimary" variant="body1">Here is the latest information about each location:</Typography>
                    </Grid>
                    <Grid item xs={2} container alignItems="center" justify="flex-end">
                        <Box>
                            <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
                                <MoreVertIcon />
                            </Button>
                        </Box>
                    <Menu
                        id="fade-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        TransitionComponent={Fade}
                    >
                        <MenuItem onClick={handleClose}>Click 1</MenuItem>
                        <MenuItem onClick={handleClose}>Click 2</MenuItem>
                    </Menu>
                    </Grid>
                </Grid>
            </Box>
            {/* Start of Loop */}
                {(locations? 
                    locations.map((location) => {
                        return(
                            <Box key={`location${location.id}`}>
                                <Box className={classes.location} onClick={ () => { onClickLocation(location.title, location.id) }}>
                                    <Box pl={2} display="flex" alignItems="center">
                                        <Grid className={classes.locationInner} container>
                                            <Grid item md={11} xs={10} container alignItems="center" className={classes.leftCol}>
                                                <Typography variant="h4" color="textSecondary" style={{ fontWeight: '700', textTransform: 'uppercase' }}>{location.title}</Typography>
                                            </Grid>
                                            <Grid item md={1} xs={2} container alignItems="center" justify="center">
                                                <IconButton className={classes.DownButton} style={{ transform: currentLocation === location.title? 'rotate(180deg)': null }}>
                                                    <KeyboardArrowDownIcon style={{ color: '#fff' }} />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                                <Box pt={1} px={3} pb={1} className={classes.dropLocationInfo} style={{ display: currentLocation === location.title? 'block': 'none' }}>
                                    {(
                                        loading === true?
                                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} py={4}>
                                                <CircularProgress color="primary"/>
                                            </Box>
                                        : null
                                    )}
                                    {(
                                    currentLocationData.visitorsFlow && loading === false?
                                        <Box>
                                            <Typography variant="body1" color="primary" style={{fontWeight: '700', marginBottom: '5px'}}>Visitors flow</Typography>
                                            <Grid container spacing={3}>
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <TodayVisitors data={currentLocationData.visitorsFlow.today} />
                                                </Grid>
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <ThisWeekVisitors data={currentLocationData.visitorsFlow.thisWeek} />
                                                </Grid>
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <ThisMonthVisitors data={currentLocationData.visitorsFlow.thisMonth} />
                                                </Grid>
                                                <Grid item lg={3} sm={6} xs={12}>
                                                    <HistoricalVisitors data={currentLocationData.visitorsFlow.histoical} />
                                                </Grid>
                                            </Grid>
                                            <Box mt={1}>
                                                <Typography variant="body1" color="primary" style={{fontWeight: '700', marginBottom: '5px'}}>Store operated under full capacity</Typography>
                                                <Grid container spacing={3}>
                                                    <Grid item lg={3} sm={6} xs={12}>
                                                        <TodayVisitorsCompliance data={currentLocationData.ComplianceLevel.today} />
                                                    </Grid>
                                                    <Grid item lg={3} sm={6} xs={12}>
                                                        <ThisWeekVisitorsCompliance data={currentLocationData.ComplianceLevel.thisWeek} />
                                                    </Grid>
                                                    <Grid item lg={3} sm={6} xs={12}>
                                                        <ThisMonthVisitorsCompliance data={currentLocationData.ComplianceLevel.thisMonth} />
                                                    </Grid>
                                                    <Grid item lg={3} sm={6} xs={12}>
                                                        <HistoricalVisitorsCompliance data={currentLocationData.ComplianceLevel.histoical} />
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Box mt={2} mb={1}>
                                                <Grid container spacing={3}>
                                                    <Grid item md={6} xs={12}>
                                                        <WeeklyOverView id={location.id} data={currentLocationData.chartWeeks} monthly={currentLocationData.monthlyData}/>
                                                    </Grid>
                                                    <Grid item md={6} xs={12}>
                                                        <VisitorsFlow id={location.id} data={currentLocationData.chart}/>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            {location.peaksSement?
                                                <Box mt={2} mb={1}>
                                                    <Grid container spacing={3}>
                                                        <Grid item md={6} xs={12}>
                                                            <Peaks id={location.id} />
                                                        </Grid>
                                                        <Grid item md={6} xs={12}>
                                                            <CustomerSegment locationID={location.id} />
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            :null
                                            }
                                        </Box>
                                    :null
                                    )}
                                </Box>
                            </Box>
                        );
                    })
                    :null
                )}
            {/* End of Loop */}
        </Box>
    );
}

export default Locations;