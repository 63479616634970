import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.background.alertiee,
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  whiteColor: {
    color: theme.palette.background.default,
  }
}));

const HistoricalVisitors = ({ className, data, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box flexGrow={1}>
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
          className={classes.whiteColor}
          style={{textTransform: 'none'}}
        >
          Historical visitors
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
        >
          <Typography
            variant="h3"
            className={classes.whiteColor}
          >
            {data}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

HistoricalVisitors.propTypes = {
  className: PropTypes.string
};

export default HistoricalVisitors;