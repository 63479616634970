import React, {
  useRef,
  // useState
} from 'react';
// import { Link as RouterLink } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';
// import { useSnackbar } from 'notistack';
import {
  // Avatar,
  Box,
  ButtonBase,
  Hidden,
  // Menu,
  // MenuItem,
  Typography,
  // makeStyles
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// const useStyles = makeStyles((theme) => ({
//   avatar: {
//     height: 32,
//     width: 32,
//     marginRight: theme.spacing(1)
//   },
//   popover: {
//     width: 200
//   }
// }));

const Account = () => {
  // const classes = useStyles();
  // const history = useHistory();
  const ref = useRef(null);
  const { user } = useAuth();
  // const { user, logout } = useAuth();
  // const { enqueueSnackbar } = useSnackbar();
  // const [isOpen, setOpen] = useState(false);
  // const [ setOpen] = useState(false);
  const handleOpen = () => {
    // setOpen(true);
    return false;
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const handleLogout = async () => {
  //   try {
  //     handleClose();
  //     await logout();
  //     history.push('/');
  //   } catch (err) {
  //     console.error(err);
  //     enqueueSnackbar('Unable to logout', {
  //       variant: 'error'
  //     });
  //   }
  // };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        {/* <Avatar
          alt="User"
          className={classes.avatar}
          src={`/static/icons/${user.logo}`}
        /> */}
        <AccountCircleIcon style={{marginRight: '10px'}} />
        <Hidden smDown>
          <Typography
            variant="h6"
            color="inherit"
          >
            {user.name}
          </Typography>
        </Hidden>
      </Box>
      {/* <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem
          component={RouterLink}
          to="/app/social/profile"
        >
          Profile
        </MenuItem>
        <MenuItem
          component={RouterLink}
          to="/app/account"
        >
          Account
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          Logout
        </MenuItem>
      </Menu> */}
    </>
  );
}

export default Account;
