import React, { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  makeStyles,
} from '@material-ui/core';
import Page from 'src/components/Page';
import Cookie from 'js-cookie';
import axios from 'axios';
import Header from './Header';
import TodayVisitors from './TodayVisitors';
import ThisWeekVisitors from './ThisWeekVisitors';
import ThisMonthVisitors from './ThisMonthVisitors';
import HistoricalVisitors from './HistoricalVisitors';
import Locations from './Locations';
import Loading from '../Loading/Loading';
import { BackendURL } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  mainContainer: {
    [theme.breakpoints.up('md')]:{
      maxWidth: '1500px'
    }
  }
}));

const OverviewView = () => {
  const classes = useStyles();
  const [currentLocationData, setCurrentLocationData] = useState({});
  const [loading, setLoading] = useState(false);

  const getLocationInformation = useCallback(async () => {
    var r = 0;
    if(Cookie.get('accepted_update') === "true"){
      r = false;
    }else{
      r = window.confirm("We are updating the data of sensors and camera, please bear with us.");
    }
    if (r === true) {
      // console.log(true);
      Cookie.set('accepted_update', true, { path: '/', expires: 7 });
    } 
    try{
      setLoading(true);
      let header = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'responseType': 'json',
          'Content-Type': 'application/json;charset=utf-8',
          'Api-x-key': 'H3DdKEuctPHVDWYgYOqydCAh08BFj_-hziYlrIbwvMI'
        }
      }
      // const operations = await axios.post('//api.alertiee.io/operations/collection', {"Client": "Adnams"}, header);
      // console.log(operations);
      const res = await axios.post(BackendURL+'/operations/flow_camera', { 
        "Client": "Adnams",
        // "store_id": 240312094248867,
        // "camera_id": 201112094304641 
      }, header).catch((error) => {
        if(error.response){
          alert('Dashboard is updating with the latest live data, please re-visit this page in the near future.');
        }
      }); 

      let TodayVisitors = 0;
      let thisMonth = 0;
      let thisWeek = 0;
      let historical = 0;
      if(res.data.today !== null){
        TodayVisitors = res.data.today;
        thisMonth = res.data.thisMonth;
        thisWeek = res.data.thisWeek;
        historical = res.data.historical;
      }
      let data = {};
        data = {
          id: 1,
          today: {
            value: TodayVisitors,
            difference: res.data.growthDay  === "N/A"? 0: res.data.growthDay,
          },
          thisWeek: {
            value: thisWeek,
            difference: res.data.growthWeek === "N/A"? 0: res.data.growthWeek,
          },
          thisMonth: {
            value: thisMonth,
            difference: res.data.growthMonth === "N/A"? 0: res.data.growthMonth,
          },
          histoical: historical
        };
        setCurrentLocationData(data);
        setLoading(false);
        // console.log(data);
    }catch(error){
        console.log(error);
    }
  }, []);

  useEffect(() => {
    getLocationInformation();
  }, [getLocationInformation]);

  if (!currentLocationData) {
    return null;
  }

  return (
    <Page className={classes.root} title="Overview">
      <Loading status={loading}/>
      <Container className={classes.mainContainer}>
        <Header />
        <Grid container spacing={3}>
          <Grid item lg={3} sm={6} xs={12}>
            <TodayVisitors data={currentLocationData.today} />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ThisWeekVisitors data={currentLocationData.thisWeek} />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <ThisMonthVisitors data={currentLocationData.thisMonth} />
          </Grid>
          <Grid item lg={3} sm={6} xs={12}>
            <HistoricalVisitors data={currentLocationData.histoical} />
          </Grid>
        </Grid>
        <Box mt={5}>
          <Locations />
        </Box>
      </Container>
    </Page>
  );
};

export default OverviewView;
