import React, {
  useState,
  useEffect,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
// import GenericMoreButton from 'src/components/GenericMoreButton';
import axios from 'axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Chart from './Chart';
import { BackendURL } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    textAlign: 'center',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(1, 1),
    '&:not(:last-of-type)': {
      borderRight: `1px solid #eeeeee`
    }
  }
}));

const CustomerSegment = ({ className, locationID, ...rest }) => {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [earnings, setEarnings] = useState(null);

  const getGenders = useCallback(async () => {
    try  {
      const response = await axios.post(BackendURL+'/operations/gender', { Client: "Adnams", store_id: locationID });
      let count = 0;
      response.data.data.map((item) => {
        count = count + item;
        return false;
      });
      let dataArr = [];
      response.data.data.map((item) => {
        dataArr.push(item/count*100);
        return false;
      });
      // console.log(dataArr);
      let data = {
        earnings: {
          datasets: [
            {
              data: dataArr,
              backgroundColor: ['#5850ec', 'rgba(63, 81, 181, 0.5)']
              // backgroundColor: ['#5850ec', 'rgba(63, 81, 181, 0.5)', 'rgba(63, 81, 181, 0.3)']
            }
          ],
          labels: response.data.labels
        }
      };
      if (isMountedRef.current && response.data.data) {
        setEarnings(data.earnings);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef, locationID]);

  useEffect(() => {
    getGenders();
  }, [getGenders]);

  if (!earnings) {
    return null;
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        // action={<GenericMoreButton />}
        title="Customer segment"
      />
      <Divider />
      <Box
        px={3} py={1}
        position="relative"
        minHeight={320}
      >
        <Chart data={earnings} />
      </Box>
      <Box display="flex">
        {earnings.labels.map((label, i) => (
          <div
            key={label}
            className={classes.item}
          >
            <Box style={{backgroundColor: earnings.datasets[0].backgroundColor[i], width: '78px', height: '9px', margin: '0px auto 9px auto'}}></Box>
            <Typography
              variant="h4"
              color="textPrimary"
            >
              {earnings.datasets[0].data[i]}
              %
            </Typography>
            <Typography
              variant="overline"
              color="textSecondary"
            >
              {label}
            </Typography>
          </div>
        ))}
      </Box>
    </Card>
  );
};

CustomerSegment.propTypes = {
  className: PropTypes.string
};

export default CustomerSegment;
