import React from 'react';
import {
  IconButton,
  SvgIcon,
  Tooltip,
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';


const Logout = () => {

    const { logout } = useAuth();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      await logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  return (
        <Tooltip title="Logout">
            <IconButton color="inherit" onClick={handleLogout}>
            <SvgIcon fontSize="small">
                <ExitToAppIcon />
            </SvgIcon>
            </IconButton>
        </Tooltip>
  );
};

export default Logout;
