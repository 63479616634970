import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Divider,
  makeStyles
} from '@material-ui/core';
import Chart from './Chart';
import axios from 'axios';
import PickDateForm from './PickDateForm';
import { BackendURL } from 'src/constants';

const useStyles = makeStyles(() => ({
  root: {},
  chart: {
    height: '100%'
  }
}));

const VisitorsFlow = ({ className, id, data,...rest }) => {
  const classes = useStyles();
  const [newData, setNewData] = useState();
  const [title, setTitle] = useState('Historical daily view');

  const setData = async (date) => {
    setTitle(date+' visitors flow');
    let header = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'responseType': 'json',
        'Content-Type': 'application/json;charset=utf-8',
        'Api-x-key': 'H3DdKEuctPHVDWYgYOqydCAh08BFj_-hziYlrIbwvMI'
      }
    }
    let reqVal = { 
        "Client": "Adnams",
        "store_id": id,
        "date": date
    };
    const res = await axios.post(BackendURL+'/operations/compliance_camera', reqVal, header); 
    setNewData({            
          values: res.data.chart.data,
          labels: res.data.chart.labels,
    });
  }

  if(!data){
    return null;
  }

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        className="cardHeaderVisitorsFlow"
        action={<PickDateForm setData={setData}/>}
        title={title}
      />
      <Divider />
      <CardContent>
        <PerfectScrollbar>
          <Box
            height={375}
            minWidth={500}
          >
          {
            newData? 
              <Chart
                className={classes.chart}
                data={newData.values}
                labels={newData.labels}
              />:
              <Chart
                className={classes.chart}
                data={data.values}
                labels={data.labels}
              />
          }
          </Box>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

VisitorsFlow.propTypes = {
  className: PropTypes.string
};

export default VisitorsFlow;
