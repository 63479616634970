import React from 'react';
import {
  Box,
  makeStyles,
  CircularProgress,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    width: '100%',
    transition: 'all 0.5s ease',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed', 
    top: '0px',
    left: '0px',
    zIndex: '100',
  },
  hide: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    width: '100%',
    transition: 'all 0.5s ease',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed', 
    top: '0px',
    left: '0px',
    zIndex: '-100',
    opacity: 0,
    PointerEvent: 'none'
  }
}));

const Loading = ({status}) => {
  const classes = useStyles();
  // console.log(status);
    return (
      <Box className={status === false? classes.hide: classes.root}>
          <CircularProgress color="primary"/>
        </Box>
    );
}
export default Loading;