import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
} from '@material-ui/core';
import axios from 'axios';
import { BackendURL } from 'src/constants';

class Peaks extends React.Component {

    state = {
        peaks_days: null,
        peaks_hours: null,
    }
    constructor(props){
        super(props);
        let header = {
            headers: {
              'Access-Control-Allow-Origin': '*',
              'responseType': 'json',
              'Content-Type': 'application/json;charset=utf-8',
              'Api-x-key': 'H3DdKEuctPHVDWYgYOqydCAh08BFj_-hziYlrIbwvMI'
            }
        }
        let reqVal = { 
            "Client": "Adnams",
            "store_id": props.id,
        };
        axios.post(BackendURL+'/operations/peak', reqVal, header).then((res) => {
            let arr = [];
            if(!res.data.peak){
                return false;
            }
            for(let x = 0; x < res.data.peak.labels_days.length; x++){
                arr.push([x, res.data.peak.labels_days[x], Math.round(res.data.peak.data_days[x])]);
            }
            this.setState({ peaks_days: arr });

            let arr2 = [];
            for(let x = 0; x < res.data.peak.labels_hour.length; x++){
                arr2.push([x, res.data.peak.labels_hour[x], Math.round(res.data.peak.data_hour[x])]);
            }
            this.setState({ peaks_hours: arr2 });
        });
    }   
    classes = {
        circle: {
            backgroundColor: '#0d5ae5',
            width: '33px',
            height: '33px',
            borderRadius: '50%',
        },
        Rows: {
            boxShadow: '0px 1px 0px 0px #eeeeee',
            padding: '0px 0px 0px 20px'
        }
    };

    render(){
        if(this.state.peaks_hours === null || this.state.peaks_days === null){
            return false;
        }
        return (
            <Box>
                <Card>
                    <CardHeader title="Peak days" />
                    <Divider />
                    <CardContent style={{padding: '0px'}}>
                        <PerfectScrollbar>
                            {
                                this.state.peaks_days.map((peak) => {
                                    return(
                                        <Grid container style={this.classes.Rows}>
                                            <Grid item md={1} xs={2} style={{minHeight: '54px'}} container  alignItems="center">
                                                <Box style={this.classes.circle} display="flex" alignItems="center" justifyContent="center">
                                                    <Typography variant="body1" style={{color: '#fff'}}>{1+peak[0]}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item container md={9} xs={8} alignItems="center">
                                                <Typography variant="body1" color="textPrimary">{peak[1]}</Typography>
                                            </Grid>
                                            <Grid item container md={2} xs={2} alignItems="center" justify="center">
                                                <Typography style={{textAlign: 'center'}} variant="body2" color="textSecondary">{peak[2]}</Typography>
                                            </Grid>
                                        </Grid>
                                    );
                                })
                            }
                        </PerfectScrollbar>
                    </CardContent>
                </Card>
                <Card style={{marginTop: '30px'}}>
                    <CardHeader title="Peak hours" />
                    <Divider />
                    <CardContent style={{padding: '0px'}}>
                        <PerfectScrollbar>
                            {
                                this.state.peaks_hours.map((peak) => {
                                    return(
                                        <Grid container style={this.classes.Rows}>
                                            <Grid item md={1} xs={2} style={{minHeight: '54px'}} container  alignItems="center">
                                                <Box style={this.classes.circle} display="flex" alignItems="center" justifyContent="center">
                                                    <Typography variant="body1" style={{color: '#fff'}}>{1+peak[0]}</Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item container md={9} xs={8} alignItems="center">
                                                <Typography variant="body1" color="textPrimary">{peak[1]} - {1 + peak[1]} </Typography>
                                            </Grid>
                                            <Grid item container md={2} xs={2} alignItems="center" justify="center">
                                                <Typography style={{textAlign: 'center'}} variant="body2" color="textSecondary">{peak[2]}</Typography>
                                            </Grid>
                                        </Grid>
                                    );
                                })
                            }
                        </PerfectScrollbar>
                    </CardContent>
                </Card>
            </Box>
        );
    }
}
export default Peaks; 