import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Button,
  Menu,
  CircularProgress,
  MenuItem,
} from '@material-ui/core';
import moment from "moment";
import Chart from './Chart';
import axios from 'axios';
import PickDateForm from './PickDateForm';
import { CSVLink } from "react-csv";
import GetAppIcon from '@material-ui/icons/GetApp';
import { BackendURL } from 'src/constants';
// import { prepareDataForValidation } from 'formik';


class WeeklyOverView extends React.Component {

  state = {
    newData: null,
    csvData: null,
    monthlyCsvData: null,
    LastThreeMonthsCsvData: null,
    title: 'Weekly overview',
    // toolbar
    hidden: true,
    anchorEl: null,
  };
  componentDidMount() {
    this.prepearData();
  }

  prepearData(){
    let array = [
      ["Day", "Date", "Daily data", "Average"],
    ];
    for(let x = 0; x <= this.props.data.labels.length -1; x++){
      array.push([this.props.data.labels[x], this.props.data.dates[x], this.props.data.values.thisWeek[x], this.props.data.values.lastWeek[x]]);
    }
    this.setState({csvData: array});

    let array1 = [
      ["Day", "Date", "Daily data"],
    ];
    for(let x = 0; x <= this.props.monthly.labels.length -1; x++){
      array1.push([this.props.monthly.labels[x], this.props.monthly.dates[x], this.props.monthly.values[x]]);
    }
    this.setState({monthlyCsvData: array1});
    // Last three months 
    let header = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'responseType': 'json',
        'Content-Type': 'application/json;charset=utf-8',
        'Api-x-key': 'H3DdKEuctPHVDWYgYOqydCAh08BFj_-hziYlrIbwvMI'
      }
    }
    let reqVal = { 
        "Client": "Adnams",
        "store_id": this.props.id,
        "date": moment().format("YYYY-MM-DD"),
        "weeks": 12
    };
    axios.post(BackendURL+'/operations/export', reqVal, header).then((res) => {
      if(!res.data.chart){
        return false;
      }
      let array2 = [
        ["Day", "Date", "Daily data"],
      ];
      for(let x = 0; x <= res.data.chart.labels.length -1; x++){
        array2.push([res.data.chart.labels[x], res.data.chart.dates[x], res.data.chart.data_daily[x]]);
      }
      this.setState({LastThreeMonthsCsvData: array2});
    }); 
  }
  // console.log(csvData);
  setData = async (date) => {
    this.state.title = date+' Weekly overview';
    let header = {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'responseType': 'json',
        'Content-Type': 'application/json;charset=utf-8',
        'Api-x-key': 'H3DdKEuctPHVDWYgYOqydCAh08BFj_-hziYlrIbwvMI'
      }
    }
    let reqVal = { 
        "Client": "Adnams",
        "store_id": this.props.id,
        "date": date
    };
    const res = await axios.post(BackendURL+'/operations/weekly_flow', reqVal, header); 
    this.setState({newData: {            
      values: {
          thisWeek: res.data.chart.data_daily,
          lastWeek: res.data.chart.data_average
      },
      labels: res.data.chart.labels,
      dates: res.data.chart.dates
    }});
    let array = [
      ["Day", "Date", "Daily data", "Average"],
    ];
    for(let x = 0; x <= res.data.chart.labels.length -1; x++){
      array.push([res.data.chart.labels[x], res.data.chart.dates[x], res.data.chart.data_daily[x], res.data.chart.data_average[x]]);
    }
    this.setState({csvData : array});
  }
  // Toolbar export menu
  handleClick = (event) => {
    console.log(event);
    this.setState({anchorEl : event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl : null});
  };

  ShowToolbar = () => {
    this.setState({hidden : false});
  }
  HideToolbar = () => {
    this.setState({hidden : true});
  }
render(){
  const monthlyCsvData = this.state.monthlyCsvData;
  const csvData = this.state.csvData;
  const LastThreeMonthsCsvData = this.state.LastThreeMonthsCsvData;
  const open = Boolean(this.state.anchorEl);
  if(csvData === null || LastThreeMonthsCsvData === null){
    return (
        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} py={4}>
            <CircularProgress color="primary"/>
        </Box>
    );
  }

  return (
    <Card
      onMouseOver={this.ShowToolbar}
      onMouseOut={this.HideToolbar}
    >
      <CardHeader
        className="cardHeaderVisitorsFlow"
        action={<PickDateForm setData={this.setData}/>}
        title={this.state.title}
      />
      <Divider />
      <PerfectScrollbar>
        <Box
          minWidth={700}
          pt={2}
          pr={2}
          pl={2}
        >
          {
            this.state.newData? 
            <Chart
              style={{height: '400px'}}
              data={this.state.newData.values}
              labels={this.state.newData.labels}
            />:
            <Chart
              style={{height: '400px'}}
              data={this.props.data.values}
              labels={this.props.data.labels}
            />
          }
        </Box>
      </PerfectScrollbar>
          <Box textAlign="center" p={2} style={{ display: this.state.hidden === true? 'none': 'block' }}>
            <Button variant="outlined" endIcon={<GetAppIcon/>} onClick={this.handleClick}>
                Export data
            </Button>
            <Menu
                anchorEl={this.state.anchorEl}
                keepMounted
                open={open}
                onClose={this.handleClose}
            >
                <MenuItem key={1}>              
                  <CSVLink data={csvData} filename={"Weekly-Report.csv"} style={{color: 'inherit', textDecoration: 'none'}}>
                      this Week
                  </CSVLink>
                </MenuItem>
                <MenuItem key={2}>
                  <CSVLink data={monthlyCsvData} filename={"Monthly-Report.csv"} style={{color: 'inherit', textDecoration: 'none'}}>
                      this Month
                  </CSVLink>
                </MenuItem>
                <MenuItem key={3}>
                  <CSVLink data={LastThreeMonthsCsvData} filename={"Last-three-months-Report.csv"} style={{color: 'inherit', textDecoration: 'none'}}>
                    last three Months
                  </CSVLink>
                </MenuItem>
            </Menu>
          </Box>
    </Card>
  );
}

};

export default WeeklyOverView;
