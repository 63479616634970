import React, { Fragment, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { makeStyles } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]:{
      maxWidth: '66%',
      position: 'relative',
      right: '30px'
    }
  },
}));

const PickDateForm = ({setData}) => {
    const classes = useStyles();
    const [selectedDate, setDate] = useState(moment());
    const [inputValue, setInputValue] = useState(moment().subtract(1, 'day').format("YYYY-MM-DD"));

    const onDateChange = (date, value) => {
        setDate(date);
        setInputValue(value);
        setData(value);
      };
    
      const dateFormatter = str => {
        return str;
      };
    return(
        <Formik
            enableReinitialize
            initialValues={{
                date: new Date(),
            }}
            validationSchema={Yup.object().shape({
                date: Yup.date().required('Close Date is required'),
            })}
            onSubmit={async (values, {
            // resetForm,
            setErrors,
            setStatus,
            setSubmitting,
            }) => {
            try {
                console.log(values);
            } catch (err) {
                console.error(err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
            }
            }}
        >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          touched,
          values,
        }) => (
            <form onSubmit={handleSubmit} className={classes.root}>
            <Fragment>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="yyyy-MM-DD"
                    margin="normal"
                    id="date-picker-inline"
                    label="Pick a date"
                    value={selectedDate}
                    inputValue={inputValue}
                    onChange={onDateChange}
                    rifmFormatter={dateFormatter}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
              </MuiPickersUtilsProvider>
            </Fragment>
            </form>
        )}
      </Formik>
    );
}
export default PickDateForm;