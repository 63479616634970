import React from 'react';

const LogoBell = (props) => {
  return (
    <img
      alt="Logo Bell"
      style={{maxWidth: '112px'}}
      src="/static/icons/logo-white.svg"
      {...props}
    />
  );
}

export default LogoBell;